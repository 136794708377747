import classNames from 'classnames';
import { SvgAnimation, Icon } from '@/components/standalone';
import { useRef } from 'react';
import useOnScreen from '@/helpers/useOnScreen';
import globalPropTypes from '@/components/globalPropTypes';
import styles from './IntroSection.module.scss';

function IntroSection() {
  const labelsRef = useRef();
  const contentRef = useRef();
  const { isOnScreen: isContentOnScreen } = useOnScreen({
    ref: contentRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });
  const { isOnScreen: isLabelsOnScreen } = useOnScreen({
    ref: labelsRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  return (
    <div className={classNames('section', styles.introSection)} id="intro">
      <div ref={labelsRef}>
        <SvgAnimation
          name="Labels"
          autoplay={isLabelsOnScreen}
          className={styles.introSectionAnimation}
          onComplete={(animation) => {
            animation.playSegments(
              [2.05 * 60, animation.getDuration() * 60],
              true,
            );
            // eslint-disable-next-line no-param-reassign
            animation.loop = true;
          }}
        />
      </div>
      <div
        className={classNames({
          [styles.introSectionContentAppear]: isContentOnScreen,
        })}
        ref={contentRef}
      >
        <div className="tb-tag dark">What is Trustblock</div>
        <h2>Balancing trust & security</h2>
        <p>
          Audits published on Trustblock protect users from scams, boost web3
          scalability and composability by providing critical data to the
          decentralized world.
        </p>
        <div className={styles.introCheckList}>
          <div className={styles.introCheckListCol}>
            <div>
              <Icon name="CustomCheckbox" />
              Ownerless
            </div>
            <div>
              <Icon name="CustomCheckbox" />
              Immutable
            </div>
            <div>
              <Icon name="CustomCheckbox" />
              Verifiable
            </div>
          </div>
          <div className={styles.introCheckListCol}>
            <div>
              <Icon name="CustomCheckbox" />
              Unique ID
            </div>
            <div>
              <Icon name="CustomCheckbox" />
              Tied to Smart Contract(s)
            </div>
            <div>
              <Icon name="CustomCheckbox" />
              Tied to Auditor&apos;s Wallet
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

IntroSection.propTypes = globalPropTypes;
export default IntroSection;
