import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import useOnScreen from '@/helpers/useOnScreen';
import solidProofLogo from 'public/images/solidproof-mini.png';
import quillAuditsLogo from 'public/images/quillaudits-mini.png';
import hashexLogo from 'public/images/hashex-mini.png';
import { Icon } from '@/components/standalone';
import Image from 'next/image';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import globalPropTypes from '@/components/globalPropTypes';
import styles from './NetworkSection.module.scss';

const tweetCards = [
  {
    image: hashexLogo,
    name: 'HashEx DeFi Intelligence',
    username: '@HashExOfficial',
    content:
      "We're happy to announce our new partnership with @TrustblockHQ! #HashEx has joined their curated list of auditors & can now upload our #audits on-chain.",
    footer: '8:00 AM · Mar 25, 2022',
  },
  {
    image: quillAuditsLogo,
    name: 'QuillAudits',
    username: '@QuillAudits',
    content:
      "💥We're thrilled to partner with @TrustblockHQ. Together, we'll enhance web3 security, promoting openness, accessibility, and transparency.",
    footer: '1:26 PM · Mar 31, 2022',
    url: 'https://twitter.com/QuillAudits/status/1664163676851671040',
  },
  {
    image: solidProofLogo,
    name: 'SolidProof',
    username: '@SolidProof_io',
    content:
      '🆕 SolidProof is now partnered with @TrustblockHQ! We are proud to be among the first security providers to onboard the Trustblock security hub!',
    footer: '5:01 PM · May 31, 2023',
    url: 'https://twitter.com/SolidProof_io/status/1663923708455530496',
  },
];

function NetworkSection() {
  const headingRef = useRef();
  const { isOnScreen: isHeadingOnScreen } = useOnScreen({
    ref: headingRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });
  const tweetCardRef = useRef();
  const [currentTweetCardIndex, setCurrentTweetCardIndex] = useState(0);

  useEffect(() => {
    let timeout;
    if (isHeadingOnScreen) {
      timeout = setTimeout(() => {
        if (currentTweetCardIndex === tweetCards.length - 1) {
          setCurrentTweetCardIndex(0);
        } else {
          setCurrentTweetCardIndex(currentTweetCardIndex + 1);
        }
      }, 4000);
    }

    return () => {
      if (isHeadingOnScreen) clearTimeout(timeout);
    };
  }, [currentTweetCardIndex, setCurrentTweetCardIndex, isHeadingOnScreen]);

  return (
    <div className={classNames('section', styles.networkSection)}>
      <div className="max-content-width">
        <div
          className={classNames({
            [styles.networkSectionHeadingAppear]: isHeadingOnScreen,
          })}
          ref={headingRef}
        >
          <div className="tb-tag">Get onboard</div>
          <h2>Join the network</h2>
          <div className={styles.networkSectionTweetCardsWrapper}>
            {tweetCards.map((tweetCard) => (
              <div className={styles.tweetCard} key={tweetCard.username}>
                <Icon name="Twitter" />
                <div>
                  <Image
                    src={tweetCard.image}
                    alt={tweetCard.name}
                    quality={100}
                  />
                  <div>
                    <h4>{tweetCard.name}</h4>
                    <span>{tweetCard.username}</span>
                  </div>
                </div>
                <p>{tweetCard.content}</p>
                <span>{tweetCard.footer}</span>
              </div>
            ))}
          </div>
          <div className={styles.networkSectionTweetCardsWrapperSmall}>
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={currentTweetCardIndex}
                nodeRef={tweetCardRef}
                addEndListener={(done) => {
                  tweetCardRef.current.addEventListener(
                    'transitionend',
                    done,
                    false,
                  );
                }}
                classNames="fade"
              >
                <div
                  className={classNames(
                    styles.tweetCard,
                    styles[`tweetCard${currentTweetCardIndex}`],
                  )}
                  ref={tweetCardRef}
                >
                  <Icon name="Twitter" />
                  <div>
                    <Image
                      src={tweetCards[currentTweetCardIndex].image}
                      alt={tweetCards[currentTweetCardIndex].name}
                      quality={100}
                    />
                    <div>
                      <h4>{tweetCards[currentTweetCardIndex].name}</h4>
                      <span>{tweetCards[currentTweetCardIndex].username}</span>
                    </div>
                  </div>
                  <p>{tweetCards[currentTweetCardIndex].content}</p>
                  <span>{tweetCards[currentTweetCardIndex].footer}</span>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </div>
      </div>
    </div>
  );
}

NetworkSection.propTypes = globalPropTypes;
export default NetworkSection;
