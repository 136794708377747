import classNames from 'classnames';
import { Icon, SvgAnimation } from '@/components/standalone';
import { useEffect, useRef, useState } from 'react';
import useOnScreen from '@/helpers/useOnScreen';
import globalPropTypes from '@/components/globalPropTypes';
import Link from 'next/link';
import styles from './AuditSection.module.scss';

const cardAnimationHover = (animationInstance, start) => {
  if (animationInstance.getDuration() * 60 > 3 * 60) {
    animationInstance.playSegments(
      [start * 60, animationInstance.getDuration() * 60],
      true,
    );
  } else {
    animationInstance.play();
  }
  // eslint-disable-next-line no-param-reassign
  animationInstance.loop = true;
};

const cardAnimationLeave = (animationInstance) => {
  // eslint-disable-next-line no-param-reassign
  animationInstance.loop = false;
};

function AuditSection() {
  const headingRef = useRef();
  const { isOnScreen: isHeadingOnScreen } = useOnScreen({
    ref: headingRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  const [auditorCardReadyForHover, setAuditorCardReadyForHover] =
    useState(false);
  const [auditorCardAnimation, setAuditorCardAnimation] = useState(null);
  const auditorCardRef = useRef();
  const { isOnScreen: isAuditorCardOnScreen } = useOnScreen({
    ref: auditorCardRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  useEffect(() => {
    const auditorAnimationNode = document?.getElementById('auditor-animation');
    const onAuditorCardHover = () => {
      cardAnimationHover(auditorCardAnimation, 3);
    };

    const onAuditorCardLeave = () => {
      cardAnimationLeave(auditorCardAnimation);
    };
    auditorAnimationNode.addEventListener('mouseover', onAuditorCardHover);
    auditorAnimationNode.addEventListener('mouseleave', onAuditorCardLeave);

    return () => {
      auditorAnimationNode.removeEventListener('mouseover', onAuditorCardHover);
      auditorAnimationNode.removeEventListener(
        'mouseleave',
        onAuditorCardLeave,
      );
    };
  }, [auditorCardAnimation, auditorCardReadyForHover]);

  const [platformCardReadyForHover, setPlatformCardReadyForHover] =
    useState(false);
  const [platformCardAnimation, setPlatformCardAnimation] = useState(null);
  const platformCardRef = useRef();
  const { isOnScreen: isPlatformCardOnScreen } = useOnScreen({
    ref: platformCardRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  useEffect(() => {
    const platformAnimationNode =
      document?.getElementById('platform-animation');
    const onPlatformCardHover = () => {
      cardAnimationHover(platformCardAnimation, 2.5);
    };

    const onPlatformCardLeave = () => {
      cardAnimationLeave(platformCardAnimation);
    };
    platformAnimationNode.addEventListener('mouseover', onPlatformCardHover);
    platformAnimationNode.addEventListener('mouseleave', onPlatformCardLeave);

    return () => {
      platformAnimationNode.removeEventListener(
        'mouseover',
        onPlatformCardHover,
      );
      platformAnimationNode.removeEventListener(
        'mouseleave',
        onPlatformCardLeave,
      );
    };
  }, [platformCardAnimation, platformCardReadyForHover]);

  const [projectCardReadyForHover, setProjectCardReadyForHover] =
    useState(false);
  const [projectCardAnimation, setProjectCardAnimation] = useState(null);
  const projectCardRef = useRef();
  const { isOnScreen: isProjectCardOnScreen } = useOnScreen({
    ref: projectCardRef,
    threshold: 0.3,
    rootMargin: '0px',
    isOnce: true,
  });

  useEffect(() => {
    const projectAnimationNode = document?.getElementById('project-animation');

    const onProjectCardHover = () => {
      cardAnimationHover(projectCardAnimation, 2.5);
    };

    const onProjectCardLeave = () => {
      cardAnimationLeave(projectCardAnimation);
    };
    projectAnimationNode.addEventListener('mouseover', onProjectCardHover);
    projectAnimationNode.addEventListener('mouseleave', onProjectCardLeave);

    return () => {
      projectAnimationNode.removeEventListener('mouseover', onProjectCardHover);
      projectAnimationNode.removeEventListener(
        'mouseleave',
        onProjectCardLeave,
      );
    };
  }, [projectCardAnimation, projectCardReadyForHover]);

  return (
    <div
      className={classNames(
        'section',
        'max-content-width',
        styles.auditSection,
      )}
    >
      <div
        className={classNames({
          [styles.auditSectionHeadingAppear]: isHeadingOnScreen,
        })}
        ref={headingRef}
      >
        <div className="tb-tag">360° web3 vision</div>
        <h2>Make use of audits</h2>
        <p>
          <span
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            Audits shouldn&apos;t be opaque, isolated pdf reports.
          </span>
          <br />
          Trustblock makes them reactive and intelligent objects usable by
          everyone.
        </p>
      </div>
      <div>
        <Link
          className={classNames(styles.card, {
            [styles.cardAppear]: isAuditorCardOnScreen,
          })}
          href="/auditors"
          ref={auditorCardRef}
        >
          <div>
            <SvgAnimation
              name="Auditors"
              autoplay={isAuditorCardOnScreen}
              id="auditor-animation"
              className={styles.cardAnimation}
              onLoaded={(animation) => setAuditorCardAnimation(animation)}
              onComplete={() => {
                setAuditorCardReadyForHover(true);
              }}
            />
          </div>
          <div>
            <h4>Auditors</h4>
            <p>
              Build your reputation with verifiable and insightful business
              metrics and publish your audits seamlessly for free.
            </p>
            <div className={styles.cardLabel}>
              <Icon name="ArrowUpRight" />
              Learn more
            </div>
          </div>
        </Link>
        <Link
          className={classNames(styles.card, {
            [styles.cardAppear]: isPlatformCardOnScreen,
          })}
          href="/integrators"
          ref={platformCardRef}
        >
          <div>
            <SvgAnimation
              name="Platforms"
              autoplay={isPlatformCardOnScreen}
              id="platform-animation"
              className={styles.cardAnimation}
              onLoaded={(animation) => setPlatformCardAnimation(animation)}
              onComplete={() => {
                setPlatformCardReadyForHover(true);
              }}
            />
          </div>
          <div>
            <h4>Integrators</h4>
            <p>
              Leverage Trustblock&apos;s one of a kind data hub to improve your
              UI/UX with labels and connect critical data to your contracts.
            </p>
            <div className={styles.cardLabel}>
              <Icon name="ArrowUpRight" />
              Learn more
            </div>
          </div>
        </Link>
        <Link
          className={classNames(styles.card, {
            [styles.cardAppear]: isProjectCardOnScreen,
          })}
          href="https://app.trustblock.run"
          ref={projectCardRef}
        >
          <div>
            <SvgAnimation
              name="Projects"
              autoplay={isProjectCardOnScreen}
              id="project-animation"
              className={styles.cardAnimation}
              onLoaded={(animation) => setProjectCardAnimation(animation)}
              onComplete={() => {
                setProjectCardReadyForHover(true);
              }}
            />
          </div>
          <div>
            <h4>Projects</h4>
            <p>
              Get rid of the word-of-mouth based search and find the
              best-fitting auditor that truly matches your project&apos;s needs.
            </p>
            <div className={styles.cardLabel}>
              <Icon name="ArrowUpRight" />
              Go to app
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

AuditSection.propTypes = globalPropTypes;
export default AuditSection;
