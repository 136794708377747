/* eslint-disable jsx-a11y/media-has-caption */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@/components/standalone';
import { useEffect, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import heroImageSmall from 'public/images/hero-image-small.jpg';
import heroImage from 'public/images/hero-image.jpg';
import styles from './HeroSection.module.scss';

const propTypes = {
  liveMetrics: PropTypes.shape(),
};

function HeroSection({ liveMetrics }) {
  const heroRef = useRef();
  const videoRef = useRef();
  const [canVideoAutoplay, setCanVideoAutoplay] = useState(true);

  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          await videoRef.current.play();
        } catch (err) {
          setCanVideoAutoplay(false);
        }
      }
    };
    playVideo();
  }, [videoRef]);

  const videos = useMemo(
    () => (
      <>
        <video
          ref={videoRef}
          className={classNames(
            styles.heroCoverVideo,
            styles.heroCoverVideoLarge,
          )}
          src="/videos/hero-video.mp4"
          autoPlay
          preload="auto"
          muted
          playsInline
        />
        <video
          ref={videoRef}
          className={classNames(
            styles.heroCoverVideo,
            styles.heroCoverVideoSmall,
          )}
          src="/videos/hero-video-small.mp4"
          preload="auto"
          autoPlay
          muted
          playsInline
        />
      </>
    ),
    [],
  );

  const images = useMemo(
    () => (
      <>
        <Image
          src={heroImage}
          alt="hero"
          fill
          quality={100}
          priority
          className={classNames(
            styles.heroCoverImage,
            styles.heroCoverImageLarge,
          )}
        />
        <Image
          src={heroImageSmall}
          alt="hero"
          fill
          quality={100}
          priority
          className={classNames(
            styles.heroCoverImage,
            styles.heroCoverImageSmall,
          )}
        />
      </>
    ),
    [],
  );

  return (
    <div
      className={classNames('section', styles.heroSection)}
      ref={heroRef}
      id="first"
    >
      <div
        className={classNames('max-content-width', styles.heroSectionContent)}
      >
        <div className={styles.heroSectionContentMain}>
          <h1>
            The ultimate security hub <br /> built for a scalable web3
          </h1>
        </div>
        <div className={styles.heroSectionContentMetricsWrapper}>
          <h2>
            $ {new Intl.NumberFormat('en-US').format(liveMetrics.totalTvs)}
          </h2>
          <span>
            is the total value secured (TVS) through{' '}
            <strong>{liveMetrics.totalAudits} audits</strong> published on
            Trustblock
            <br />
            by our <strong>{liveMetrics.totalAuditors} auditors</strong>
          </span>
        </div>
        <div className={styles.heroSectionContentButtonsWrapper}>
          <Button
            type="button"
            mode="outline"
            onClick={() => {
              const page = document.getElementById('page');
              const element = document.getElementById('intro');
              const y = element.offsetTop + window.pageYOffset - 120;

              page.scrollTo({ top: y, behavior: 'smooth' });
            }}
            icon="ArrowDown"
          >
            Discover
          </Button>
          <Button
            type="link"
            href="https://app.trustblock.run"
            icon="ArrowRight"
          >
            Go to app
          </Button>
        </div>
      </div>
      <div className={styles.heroSectionGradientWrapper}>
        <div className={styles.heroSectionGradient} />
      </div>
      {canVideoAutoplay ? videos : images}
    </div>
  );
}

HeroSection.propTypes = propTypes;

export default HeroSection;
