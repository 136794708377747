import {
  HeroSection,
  PartnersSection,
  IntroSection,
  AuditSection,
  NetworkSection,
} from '@/components/home';
import PropTypes from 'prop-types';
import Head from 'next/head';
import axios from 'axios';

const propTypes = {
  liveMetrics: PropTypes.shape(),
};

function Home({ liveMetrics }) {
  return (
    <>
      <Head>
        <title>Trustblock | Web3 data & security hub</title>
      </Head>
      <HeroSection liveMetrics={liveMetrics} />
      <PartnersSection />
      <IntroSection />
      <AuditSection />
      <NetworkSection />
    </>
  );
}

Home.propTypes = propTypes;
export default Home;

export async function getStaticProps() {
  const response = await axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_URL}/ui/landing`,
  });

  return {
    props: {
      liveMetrics: response.data,
    },
    revalidate: 84600,
  };
}
